<script
    lang="ts"
    setup
>
    type Item = {
        iconComponent: ReturnType<typeof defineComponent>
        title: string
        text: string
    }

    const items: Item[] = [
        {
            iconComponent: resolveComponent('AppIconAccountBadge'),
            title: 'index-widget-3',
            text: 'index-widget-4',
        },
        {
            iconComponent: resolveComponent('AppIconCreation'),
            title: 'index-widget-5',
            text: 'index-widget-6',
        },
        {
            iconComponent: resolveComponent('AppIconColorFill'),
            title: 'index-widget-7',
            text: 'index-widget-8',
        },
    ]
</script>

<template>
    <div class="index-section-cards gap-6">
        <IndexSectionCard
            v-for="item in items"
            :key="item.title"
            class="!basis-1/3 desktop:py-6 desktop:px-6"
        >
            <div class="flex flex-col gap-4">
                <div class="flex">
                    <div class="p-5 bg-stone rounded-[16px]">
                        <Component
                            :is="item.iconComponent"
                            color="#000"
                        />
                    </div>
                </div>

                <div class="flex flex-col gap-2">
                    <div class="index-section-card-title md:max-lg:break-all">
                        {{ $t(item.title) }}
                    </div>

                    <div class="leading-[150%]">
                        {{ $t(item.text) }}
                    </div>
                </div>
            </div>
        </IndexSectionCard>
    </div>
</template>
