<template>
    <div
        class="
            flex
            max-tablet:flex-col
            items-center
            gap-4
            py-5
            px-8
            max-tablet:px-6
            border-[1px]
            border-secondary-active
            rounded-[20px]
        "
    >
        <div class="leading-[150%]">
            {{ $t('index-tariffs-17') }}
        </div>

        <AppLink
            class="max-tablet:w-full"
            :to="{ name: 'tariffs' }"
        >
            <AppButton
                secondary
                class="max-tablet:w-full"
            >
                {{ $t('index-tariffs-18') }}
            </AppButton>
        </AppLink>
    </div>
</template>
