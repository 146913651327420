<template>
    <div class="index-section-intro-container !items-start">
        <div class="basis-1/2 flex flex-col">
            <AppHtmlContent
                :content="$t('index-widget-1')"
                class="
                    app-title
                    max-tablet:replace-br-with-padding
                    max-tablet:[&_span]:flex
                    max-tablet:[&_span]:flex-wrap
                "
            />

            <div class="index-section-subtitle desktop:max-w-[375px] mt-4 tablet:mt-8 max-tablet:mb-2">
                {{ $t('index-widget-2') }}
            </div>
        </div>

        <div class="basis-1/2">
            <AppVideoPlayer file="index-widget-1" />
        </div>
    </div>
</template>
