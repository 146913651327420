<script
    lang="ts"
    setup
>
    type Props = {
        anchor: string
    }

    const props = defineProps<Props>()
</script>

<template>
    <section
        :id="props.anchor"
        class="flex flex-col gap-6 tablet:gap-10 mt-36 tablet:mt-[200px] desktop:mt-72"
    >
        <slot />
    </section>
</template>
