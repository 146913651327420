<script
    lang="ts"
    setup
>
    definePageMeta({
        name: 'index',
        keepalive: true,
    })

    const { public: { siteUrl, siteName } } = useRuntimeConfig()
    const { t, language, locale } = useLang()

    const seoTitle = computed<string>(() => t('seo-meta-title', siteName))
    const seoDescription = computed<string>(() => t('seo-meta-description'))
    const seoImage = computed<string>(() => `${ siteUrl }/img/seo-meta-${ language }.png`)

    useSeoMeta({
        description: seoDescription,

        ogTitle: seoTitle,
        ogDescription: seoDescription,
        ogImage: seoImage,
        ogImageAlt: seoTitle,

        twitterTitle: seoTitle,
        twitterDescription: seoDescription,
        twitterImage: seoImage,
        twitterImageAlt: seoTitle,
        twitterCard: 'summary_large_image',
    })

    useSchemaOrg([
        defineWebPage({
            name: () => seoTitle.value,
            image: () => seoImage.value,
            description: () => seoDescription.value,
            inLanguage: () => locale.nativeLocale,
            potentialAction: [
                {
                    '@type': 'BuyAction',
                    'name': () => t('index-intro-4'),
                    'target': [ siteUrl + '/tariffs' ],
                    'price': '0',
                    'priceCurrency': 'USD',
                    'seller': {
                        '@type': 'Organization',
                        'name': siteName,
                    },
                },
            ],
        }),
        defineProduct({
            name: () => seoTitle.value,
            description: () => seoDescription.value,
            image: () => seoImage.value,
            url: siteUrl,
            keywords: 'чат для сайту, інтеграція месенджерів, база знань для бізнесу, підтримка клієнтів, онлайн-комунікація, аналітика клієнтів, автоматизація підтримки, Spilky, інструменти для бізнесу, персоналізований чат, багатоканальний чат, онлайн-віджет, клієнтський сервіс, інтеграція Telegram, інтеграція Viber, підтримка клієнтів PRO, Spilky база знань, бізнес-комунікація, чат для клієнтів, чат для сайта, интеграция мессенджеров, база знаний для бизнеса, поддержка клиентов, онлайн-коммуникация, аналитика клиентов, автоматизация поддержки, Spilky, инструменты для бизнеса, персонализированный чат, многоканальный чат, онлайн-виджет, клиентский сервис, интеграция Telegram, интеграция Viber, PRO поддержка клиентов, база знаний Spilky, бизнес-коммуникация, чат для клиентов',
            offers: [
                {
                    price: 0,
                    priceCurrency: 'USD',
                    url: siteUrl + '/tariffs',
                    seller: {
                        '@type': 'Organization',
                        'name': siteName,
                    },
                },
                {
                    price: 14.99,
                    priceCurrency: 'USD',
                    url: siteUrl + '/tariffs',
                    seller: {
                        '@type': 'Organization',
                        'name': siteName,
                    },
                },
            ],
            aggregateRating: {
                ratingValue: 4.9,
                bestRating: 5,
                ratingCount: 1386,
            },
            brand: {
                '@id': siteName,
                'name': siteName,
            },
        }),
    ])
</script>

<template>
    <AppPage class="tablet:!pt-20 !pb-40">
        <IndexSectionIntro />
        <IndexSectionChat />
        <IndexSectionKB />
        <IndexSectionWidget />
        <IndexSectionTariffs />
    </AppPage>
</template>
