<template>
    <div
        class="
            flex
            flex-col
            gap-6
            tablet:gap-10
            max-tablet:py-6
            max-tablet:px-4
            tablet:max-desktop:py-8
            tablet:max-desktop:px-6
            desktop:py-14
            desktop:px-14
            border-[1px]
            border-secondary-active
            rounded-[20px]
            tablet:rounded-[40px]
        "
    >
        <slot />
    </div>
</template>
