<template>
    <div class="index-section-cards">
        <IndexSectionCard class="basis-1/2">
            <div class="flex flex-col gap-6">
                <div class="index-section-card-title">
                    {{ $t('index-kb-3') }}
                </div>

                <div class="leading-[150%]">
                    {{ $t('index-kb-4') }}
                </div>
            </div>

            <div class="flex flex-col">
                <div>
                    <AppPublicImage
                        file="index-kb-2"
                        class="index-section-img-shadow"
                    />

                    <AppPublicImage
                        file="index-kb-3"
                        class="index-section-img-shadow mt-6"
                    />
                </div>
            </div>
        </IndexSectionCard>

        <IndexSectionCard
            class="
                basis-1/2
                tablet:max-desktop:items-center
                justify-between
                tablet:max-desktop:justify-start
            "
        >
            <div class="flex flex-col gap-4">
                <AppHtmlContent
                    :content="$t('index-kb-5')"
                    class="index-section-card-title"
                />

                <div class="leading-[150%]">
                    {{ $t('index-kb-6') }}
                </div>
            </div>

            <div class="flex flex-col items-center">
                <AppPublicImage
                    file="index-kb-4"
                    class="index-section-img-shadow"
                />

                <AppPublicImage
                    file="index-kb-5"
                    class="index-section-img-shadow mt-6"
                />
            </div>
        </IndexSectionCard>
    </div>
</template>
