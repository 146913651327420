<script
    lang="ts"
    setup
>
    const { desktop } = useWindowSize()
</script>

<template>
    <AppHtmlContent
        key="title"
        :content="$t('index-intro-1')"
        class="tablet:z-[1] app-title text-center mt-4"
    />

    <AppHtmlContent
        key="subtitle"
        :content="$t('index-intro-2')"
        class="index-section-subtitle mt-4 tablet:max-desktop:mt-2 text-center"
    />

    <IndexSectionIntroForm key="form" />

    <div
        key="image"
        class="flex justify-center"
    >
        <div class="max-w-[800px] tablet:min-h-[363px]">
            <AppPublicImage
                file="index-intro-1"
                class="max-tablet:pt-10"
            />
        </div>
    </div>

    <ClientOnly>
        <IndexSectionIntroShadowImages
            v-if="desktop"
            key="shadow-images"
        />
    </ClientOnly>
</template>
