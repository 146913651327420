<template>
    <div class="index-section-cards">
        <IndexSectionCard class="basis-1/2">
            <div class="flex flex-col gap-4">
                <div class="index-section-card-title">
                    {{ $t('index-chat-9') }}
                </div>

                <div class="leading-[150%]">
                    {{ $t('index-chat-10') }}
                </div>
            </div>

            <div class="flex flex-col items-start">
                <AppPublicImage
                    file="index-chat-10"
                    class="index-section-img-shadow"
                />

                <AppPublicImage
                    file="index-chat-11"
                    class="index-section-img-shadow mt-6"
                />
            </div>
        </IndexSectionCard>

        <IndexSectionCard class="basis-1/2">
            <div class="flex flex-col gap-4">
                <AppHtmlContent
                    :content="$t('index-chat-11')"
                    class="index-section-card-title"
                />

                <div class="leading-[150%]">
                    {{ $t('index-chat-12') }}
                </div>
            </div>

            <div class="flex flex-1 items-center justify-center">
                <AppPublicImage
                    file="index-chat-12"
                    class="index-section-img-shadow"
                />
            </div>
        </IndexSectionCard>
    </div>
</template>
