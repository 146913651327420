<script
    lang="ts"
    setup
>
    const emailModel = ref<string>('')

    const linkToCabinet = useCabinetAuthUrl('login', emailModel)
</script>

<template>
    <div class="mt-10 flex max-tablet:flex-col justify-center gap-4">
        <AppFormFieldInput
            v-model.trim="emailModel"
            type="email"
            name="email"
            :label="$t('index-intro-3')"
            without-error-text
            autocomplete
            class="!w-auto tablet:!w-[360px]"
        />

        <AppLink
            external
            :to="linkToCabinet"
        >
            <AppButton
                large
                class="max-tablet:w-full"
            >
                {{ $t('index-intro-4') }}
            </AppButton>
        </AppLink>
    </div>
</template>
