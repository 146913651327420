<template>
    <div class="index-section-intro-container">
        <div class="app-title basis-3/5">
            {{ $t('index-kb-1') }}
        </div>

        <div class="index-section-subtitle">
            {{ $t('index-kb-2') }}
        </div>
    </div>

    <div class="flex justify-center">
        <AppVideoPlayer
            file="index-kb-1"
            class="flex-1"
        />
    </div>
</template>
