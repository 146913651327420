<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <path
                d="M14 19H18V5H14M6 19H10V5H6V19Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
