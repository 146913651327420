<script
    lang="ts"
    setup
>
    type Props = {
        file: string
    }

    const props = defineProps<Props>()

    const playerRef = ref<HTMLMediaElement>()

    const isPlaying = ref<boolean>(false)
    const isEnded = ref<boolean>(false)
    const isIntersected = ref<boolean>(false)

    const isMounted = useMounted()
    const { isWindowFocused } = useWindowFocus()
    const { isTabActive } = useTabActivity()

    const canPlay = computed<boolean>(() => {
        return isMounted.value && isWindowFocused.value && isTabActive.value && isIntersected.value
    })

    const play = (): void => {
        if (!canPlay.value || isPlaying.value) {
            return
        }

        isPlaying.value = true
        isEnded.value = false

        playerRef.value.play()
    }

    const pause = (): void => {
        if (!isPlaying.value) {
            return
        }

        isPlaying.value = false

        playerRef.value.pause()
    }

    let observer: IntersectionObserver | undefined

    onMounted(() => {
        observer = new IntersectionObserver(
            ([ entry ]) => {
                isIntersected.value = entry.intersectionRatio > 0.5
            },
            { threshold: [ 0.5 ] },
        )

        observer.observe(playerRef.value)

        watch(
            canPlay,
            (value) => {
                if (isEnded.value) {
                    return
                }

                if (value) {
                    play()
                } else {
                    pause()
                }
            },
            { immediate: true },
        )
    })

    onBeforeUnmount(() => {
        observer?.disconnect()

        observer = undefined
    })
</script>

<template>
    <div class="relative flex items-center justify-center">
        <video
            ref="playerRef"
            key="player"
            preload="auto"
            muted
            playsinline
            :poster="`/img/${ props.file }@2x.png`"
            draggable="false"
            class="rounded-[20px] tablet:rounded-[40px]"
            @pause="isPlaying = false"
            @play="isPlaying = true"
            @ended="isEnded = true"
        >
            <source
                :src="`/video/${ props.file }.webm`"
                type="video/webm"
            >

            <source
                :src="`/video/${ props.file }.mp4`"
                type="video/mp4"
            >

            Your browser does not support the video tag
        </video>

        <div
            v-if="!canPlay"
            key="pause"
            class="
                absolute
                flex
                items-center
                justify-center
                w-[100px]
                h-[100px]
                text-[40px]
                !bg-[rgba(255,255,255,0.4)]
                rounded-[50%]
                backdrop-blur-xl
            "
        >
            <AppIconPause color="#000" />
        </div>

        <AppButton
            v-if="isEnded"
            key="repeat"
            wrapper
            class="
                absolute
                right-4
                bottom-4
                flex
                items-center
                gap-2
                !py-3
                !px-6
                font-medium
                !bg-[rgba(255,255,255,0.4)]
                rounded-3xl
                backdrop-blur-xl
            "
            @click="play()"
        >
            <AppIconRefresh color="#000" />

            {{ $t('repeat') }}
        </AppButton>
    </div>
</template>
