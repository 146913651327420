<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable @stylistic/max-len -->
            <path
                d="M19.0001 14.5C19.0001 14.5 17.0001 16.67 17.0001 18C17.0001 18.5304 17.2108 19.0391 17.5858 19.4142C17.9609 19.7893 18.4696 20 19.0001 20C19.5305 20 20.0392 19.7893 20.4143 19.4142C20.7893 19.0391 21.0001 18.5304 21.0001 18C21.0001 16.67 19.0001 14.5 19.0001 14.5ZM5.21006 13L10.0001 8.21L14.7901 13H5.21006ZM16.5601 11.94L7.62006 3L6.21006 4.41L8.59006 6.79L3.44006 11.94C2.85006 12.5 2.85006 13.47 3.44006 14.06L8.94006 19.56C9.23006 19.85 9.62006 20 10.0001 20C10.3801 20 10.7701 19.85 11.0601 19.56L16.5601 14.06C17.1501 13.47 17.1501 12.5 16.5601 11.94Z"
                :fill="color"
            />
            <!-- eslint-enable @stylistic/max-len -->
        </template>
    </AppIcon>
</template>
