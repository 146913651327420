<script
    lang="ts"
    setup
>
    import type { TariffsToggle } from '~/ts/types/tariff'

    const toggleValue = ref<TariffsToggle>('year')
</script>

<template>
    <IndexSection
        anchor="tariffs"
        class="items-start tablet:items-center !gap-4"
    >
        <div class="app-title">
            {{ $t('index-tariffs-1') }}
        </div>

        <AppHtmlContent
            :content="$t('index-tariffs-2')"
            class="text-[16px] tablet:text-[20px] leading-[150%]"
        />

        <IndexSectionTariffsToggle v-model="toggleValue" />

        <div class="flex flex-col gap-6 tablet:gap-[40px] w-full tablet:max-w-[840px]">
            <IndexSectionTariffsPlans
                :toggle-value="toggleValue"
                class="mt-6"
            />

            <IndexSectionTariffsHint />

            <IndexSectionTariffsComparison />
        </div>
    </IndexSection>
</template>
