<template>
    <IndexSection
        anchor="chat"
        class="!mt-28 tablet:!mt-40"
    >
        <IndexSectionChatPart_1 />
        <IndexSectionChatPart_2 />
        <IndexSectionChatPart_3 />
        <IndexSectionChatPart_4 />
    </IndexSection>
</template>
