import type { AnyFn } from '~/ts/types/common'

type UseTabActivity = {
    isTabActive: Ref<boolean>
    onTabActive: (fn: AnyFn) => VoidFunction
    onTabInactive: (fn: AnyFn) => VoidFunction
}

type UseTabActiveOptions = {
    listen?: boolean
}

export const useTabActivity = (options: UseTabActiveOptions = {}): UseTabActivity => {
    const {
        listen = true,
    } = options

    const { window, document } = getClientContext()

    const isTabActive = ref<boolean>(false)

    if (!window) {
        return {
            isTabActive,
            onTabActive: () => noop,
            onTabInactive: () => noop,
        }
    }

    const hooks = {
        onTabActive: createEventHook(),
        onTabInactive: createEventHook(),
    }

    const handler = (): void => {
        isTabActive.value = document.visibilityState === 'visible'

        if (listen) {
            hooks[isTabActive.value ? 'onTabActive' : 'onTabInactive'].trigger()
        }
    }

    handler()

    if (listen) {
        onEvent(window, 'visibilitychange', handler)
    }

    return {
        isTabActive,
        onTabActive: hooks.onTabActive.on,
        onTabInactive: hooks.onTabInactive.on,
    }
}
