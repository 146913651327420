<template>
    <div class="index-section-intro-container">
        <div class="app-title basis-3/4">
            {{ $t('index-chat-1') }}
        </div>

        <AppHtmlContent
            :content="$t('index-chat-2')"
            class="index-section-subtitle"
        />
    </div>

    <div class="flex justify-center">
        <AppVideoPlayer
            file="index-chat-1"
            class="flex-1"
        />
    </div>
</template>
