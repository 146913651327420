<script
    lang="ts"
    setup
>
    type SocialNetwork = {
        name: string
        file: string
    }

    const { desktop } = useWindowSize()

    const socialNetworks: SocialNetwork[] = [
        {
            name: 'Telegram',
            file: 'index-chat-3',
        },
        {
            name: 'Viber',
            file: 'index-chat-4',
        },
        {
            name: 'Instagram',
            file: 'index-chat-5',
        },
        {
            name: 'Messenger',
            file: 'index-chat-6',
        },
        {
            name: 'WhatsApp',
            file: 'index-chat-7',
        },
    ]
</script>

<template>
    <IndexSectionCard>
        <div class="flex max-tablet:flex-col items-start desktop:items-center justify-between max-tablet:gap-4">
            <div class="index-section-card-title tablet:basis-[58%] desktop:basis-2/5">
                {{ $t('index-chat-3') }}
            </div>

            <div class="basis-2/5 leading-[130%]">
                {{ $t('index-chat-4') }}
            </div>
        </div>

        <ClientOnly>
            <div
                v-if="desktop"
                key="desktop-variant"
                class="flex justify-center mt-20 mb-8"
            >
                <div class="flex flex-col items-center">
                    <IndexSectionCard class="!p-8">
                        <AppPublicImage
                            file="index-chat-2"
                            :class="$style['icon']"
                        />
                    </IndexSectionCard>

                    <div class="py-6">
                        {{ $t('your-website') }}
                    </div>
                </div>

                <div :class="[ $style['plus'], 'h-[140px] text-center' ]">
                    +
                </div>

                <div class="flex flex-col">
                    <IndexSectionCard class="!flex-row items-center !p-8">
                        <div
                            v-for="{ file } in socialNetworks"
                            :key="file"
                            class="relative flex flex-col items-center"
                        >
                            <AppPublicImage
                                key="img"
                                :file="file"
                                :class="$style['icon']"
                            />

                            <div
                                v-if="[ 'index-chat-5', 'index-chat-6', 'index-chat-7' ].includes(file)"
                                key="hint"
                                class="
                                    absolute
                                    -bottom-[10px]
                                    py-1
                                    px-2
                                    text-[12px]
                                    text-[#a2a5b4]
                                    leading-[10px]
                                    font-medium
                                    bg-[#eceef0]
                                    rounded-xl
                                "
                            >
                                {{ $t('soon') }}
                            </div>
                        </div>
                    </IndexSectionCard>

                    <div class="flex items-center gap-10 py-6 px-8">
                        <div
                            v-for="{ name } in socialNetworks"
                            :key="name"
                            class="basis-[80px] text-center"
                        >
                            {{ name }}
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-else
                key="other-variants"
                class="flex max-tablet:flex-col justify-center max-tablet:gap-2"
            >
                <div class="flex flex-col items-center">
                    <div
                        key="first"
                        class="flex flex-col items-center w-full"
                    >
                        <IndexSectionCard
                            class="
                                items-center
                                tablet:max-desktop:!gap-4
                                tablet:max-desktop:!py-6
                                tablet:max-desktop:!px-8
                                max-tablet:!py-6
                                max-tablet:!px-6
                                max-tablet:w-full
                            "
                        >
                            <AppPublicImage
                                file="index-chat-2"
                                :class="$style['icon']"
                            />

                            <div class="text-center">
                                {{ $t('your-website') }}
                            </div>
                        </IndexSectionCard>
                    </div>

                    <div
                        key="plus"
                        :class="[
                            $style['plus'],
                            `
                                tablet:max-desktop:h-[160px]
                                max-tablet:h-auto
                                max-tablet:!w-[160px]
                                max-tablet:!text-[54px]
                                max-tablet:!font-normal
                            `,
                        ]"
                    >
                        +
                    </div>
                </div>

                <IndexSectionCard
                    class="
                        basis-3/6
                        max-desktop:!flex-row
                        flex-wrap
                        max-tablet:!gap-4
                        tablet:max-desktop:!gap-8
                        tablet:max-desktop:!py-6
                        tablet:max-desktop:!px-8
                        max-tablet:!py-6
                        max-tablet:!px-2
                        max-desktop:justify-center
                    "
                >
                    <div
                        v-for="{ name, file } in socialNetworks"
                        :key="file"
                        class="flex flex-col gap-4"
                    >
                        <div class="relative flex flex-col items-center">
                            <AppPublicImage
                                :file="file"
                                :class="$style['icon']"
                            />

                            <div
                                v-if="[ 'index-chat-5', 'index-chat-6', 'index-chat-7' ].includes(file)"
                                key="hint"
                                class="
                                    absolute
                                    -bottom-[10px]
                                    p-1
                                    text-[12px]
                                    text-[#a2a5b4]
                                    leading-[10px]
                                    font-medium
                                    bg-[#eceef0]
                                    rounded-xl
                                "
                            >
                                {{ $t('soon') }}
                            </div>
                        </div>

                        <div
                            key="name"
                            class="text-center"
                        >
                            {{ name }}
                        </div>
                    </div>
                </IndexSectionCard>
            </div>
        </ClientOnly>
    </IndexSectionCard>
</template>

<style
    lang="sass"
    module
    scoped
>
    .icon
        @apply basis-[80px] w-[80px] drop-shadow-[0_50px_20px_rgba(0,0,0,0.05)]

    .plus
        @apply flex items-center justify-center w-[56px] text-[24px] font-medium
</style>
