<script
    lang="ts"
    setup
>
    type ShadowImage = {
        file: string
        class: string
        style: string
    }

    const isMounted = useMounted()

    const shadowImages: ShadowImage[] = [
        {
            file: 'index-intro-2',
            class: 'pointer-events-none absolute top-0 left-0 w-[385px]',
            style: '--translate: -50%, -50%;',
        },
        {
            file: 'index-intro-3',
            class: 'pointer-events-none absolute bottom-0 left-0 w-[465px]',
            style: '--translate: -50%, 50%;',
        },
        {
            file: 'index-intro-4',
            class: 'pointer-events-none absolute top-0 right-0 w-[330px]',
            style: '--translate: 50%, 50%;',
        },
        {
            file: 'index-intro-5',
            class: 'pointer-events-none absolute bottom-0 right-0 w-[385px]',
            style: '--translate: 100px;',
        },
    ]
</script>

<template>
    <TransitionGroup
        name="shadow-images-transition"
        appear
    >
        <div
            v-for="item in shadowImages"
            v-show="isMounted"
            :key="item.file"
            :class="item.class"
            :style="item.style"
        >
            <AppPublicImage :file="item.file" />
        </div>
    </TransitionGroup>
</template>

<style lang="sass">
    .shadow-images-transition
        &-enter-from
            opacity: 0
            transform: translate(var(--translate))

        &-enter-active
            transition: opacity 3s var(--transition-default-ease), transform 2.4s var(--transition-default-ease)

        &-enter-to
            opacity: 1
            transform: translate(0)
</style>
