<script
    lang="ts"
    setup
>
    type Item = {
        title: string
        text: string
        file: string
    }

    const items: Item[] = [
        {
            title: 'index-chat-5',
            text: 'index-chat-6',
            file: 'index-chat-8',
        },
        {
            title: 'index-chat-7',
            text: 'index-chat-8',
            file: 'index-chat-9',
        },
    ]
</script>

<template>
    <div class="index-section-cards">
        <IndexSectionCard
            v-for="item in items"
            :key="item.file"
            class="
                basis-1/2
                desktop:flex-row
                tablet:max-desktop:items-center
                tablet:max-desktop:justify-between
                desktop:py-10
                desktop:px-12
            "
        >
            <div class="flex flex-col gap-4 desktop:gap-8 basis-[60%]">
                <div class="index-section-card-title">
                    {{ $t(item.title) }}
                </div>

                <div class="leading-[150%]">
                    {{ $t(item.text) }}
                </div>
            </div>

            <div class="flex justify-center tablet:items-start basis-[40%]">
                <AppPublicImage :file="item.file" />
            </div>
        </IndexSectionCard>
    </div>
</template>
